import { FunctionComponent } from 'react';
import PikTextSize from './types/PikTextSize';
import getTextStylesForSize from './utils/getTextStylesForSize';
import { classNames } from '../../../utils/classNames';
import { unreachable } from '../../../utils/unreachable';
import { Link } from 'react-router-dom';

type LinkType =
  // Use a tag with href
  | 'external'
  // Use Link from react-router-dom
  | 'internal';

export type Props = {
  size?: PikTextSize;
  fullWidth?: boolean; // Make p tag width: 100%;
  inline?: boolean; // Render p tag inline
  openIn?: 'window' | 'new-tab';
  href: string;
  linkType?: LinkType;
  children: string;
};

const PikLink: FunctionComponent<Props> = ({
  size = 'm',
  fullWidth = false,
  inline = false,
  openIn = 'new-tab',
  href,
  linkType = 'external',
  children,
}) => {
  const linkClassNames =
    'text-pik-purple-product underline decoration-pik-purple-product hover:brightness-75';

  let sizeClassNames = '';
  if (size === 'l') {
    sizeClassNames = getTextStylesForSize('18');
  } else if (size === 'm') {
    sizeClassNames = getTextStylesForSize('16');
  } else if (size === 's') {
    sizeClassNames = getTextStylesForSize('14');
  } else if (size === 'xs') {
    sizeClassNames = getTextStylesForSize('12');
  }

  const fullWidthClassName = fullWidth ? 'w-full' : '';
  const inlineClassNames = inline ? 'inline' : '';

  let target = '_blank';
  if (openIn === 'window') {
    target = '_self';
  }

  switch (linkType) {
    case 'external':
      return (
        <a href={href} className={classNames(inlineClassNames, fullWidthClassName)} target={target}>
          <p
            className={classNames(sizeClassNames, fullWidthClassName, linkClassNames, inlineClassNames)}
          >
            {children}
          </p>
        </a>
      );
    case 'internal':
      return (
        <Link to={href} className={classNames(inlineClassNames, fullWidthClassName)} target={target}>
          <p
            className={classNames(sizeClassNames, fullWidthClassName, linkClassNames, inlineClassNames)}
          >
            {children}
          </p>
        </Link>
      );
    default:
      return unreachable(linkType);
  }
};

export default PikLink;
